import ApplicationController from './application_controller.js'

export default class extends ApplicationController {

  static values = { id: Number }
  delete(event) {
    event.preventDefault()
    if (confirm("Voulez-vous effacer cet etablissement ?")) {
          this.stimulate('Establishment#delete', this.idValue)
    }
  }

  search(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.stimulate('Establishment#search', event.target.value)
    }, 200)
  }

  reset(event) {
    event.preventDefault()
    this.stimulate('Establishment#search')
    document.getElementById('query').value=""
  }
}
