import ApplicationController from "./application_controller";
import { useClickOutside } from "stimulus-use";
/* This is the custom StimulusReflex controller for the Admin::LinkedPrestations Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
   */

  static values = { id: Number, title: String, color: String };

  connect() {
    super.connect();
    // add your code here, if applicable
    useClickOutside(this);
  }

  clickOutside(event) {
    // example to close a modal
    this.closeContextMenu();
  }

  click(event) {
    console.log("click");
  }

  afterUpdatePeriode(event) {
    document.activeElement.blur();
  }

  capitalize(str) {
    return `${str[0].toUpperCase()}${str.slice(1)}`;
    // return str[0].toUpperCase() + str.slice(1)   // without template string
  }

  createDivColor(color, date, order, instructor_id, ess = false) {
    // console.log("createDivColor", color, date, order, instructor_id);
    const div = document.createElement("div");
    var colorUpperCase = color.toUpperCase();
    div.innerHTML = window.colors[colorUpperCase] || this.capitalize(color);
    div.setAttribute("data-controller", "planning");
    div.setAttribute("data-action", "click->planning#color");
    div.setAttribute("data-planning-color-value", color);
    div.setAttribute("data-date", date);
    div.setAttribute("data-order", order);
    div.setAttribute("data-instructor-id", instructor_id);
    div.setAttribute("data-ess", ess);
    div.classList.add(color);
    div.classList.add("item");
    return div;
  }

  contextmenu(event) {
    event.preventDefault();

    const contextMenu = document.getElementById("context-menu");
    if (contextMenu == null) {
      return;
    }
    const target = event.target;
    console.log("target", target);
    // get closest parent with data-planning-id-value
    // const id = target.closest("[data-planning-id-value]").getAttribute("data-planning-id-value");
    // get closest parent with data-date
    const date = target.closest("[data-date]").getAttribute("data-date");
    // get closest parent with data-order
    const order = target.closest("[data-order]").getAttribute("data-order");
    // get closest parent with data-instructor-id
    const instructor_id = target
      .closest("[data-instructor-id]")
      .getAttribute("data-instructor-id");
    const clothest_ess = target.closest("[data-ess]");
    const ess = clothest_ess ? clothest_ess.getAttribute("data-ess") : false;
    const { clientX: x, clientY: y } = event;
    console.log("contextmenu", x, y);

    contextMenu.style.left = `${x}px`;

    // set context menu position relative to the mouse position and the screen size
    if (window.innerHeight - y < 300) {
      contextMenu.style.top = `${y - (300 - (window.innerHeight - y))}px`;
    } else {
      contextMenu.style.top = `${y}px`;
    }

    // remove class "invisible" from event-courses id
    const eventCourses = document.getElementById("event-courses");
    const eventForm = document.getElementById("event-form");
    eventCourses.classList.remove("invisible");
    eventForm.classList.add("invisible");

    // Remove div with class item
    const items = document.getElementsByClassName("item");
    while (items.length > 0) {
      items[0].parentNode.removeChild(items[0]);
    }

    // Add prestation interne in context menu
    let div = document.createElement("div");
    div.innerHTML = "Prestation interne";
    div.setAttribute("data-controller", "planning");
    div.setAttribute("data-reflex", "click->Admin::Event#add_absence");
    div.setAttribute("data-date", date);
    div.setAttribute("data-order", order);
    div.setAttribute("data-instructor-id", instructor_id);
    div.classList.add("item");
    contextMenu.append(div);

    // Add `Texte libre` in context menu
    div = document.createElement("div");
    div.innerHTML = "Texte libre";
    div.setAttribute("data-controller", "planning");
    div.setAttribute("data-reflex", "click->Admin::Event#add_text");
    div.setAttribute("data-date", date);
    div.setAttribute("data-order", order);
    div.setAttribute("data-instructor-id", instructor_id);
    div.classList.add("item");
    contextMenu.append(div);

    // contextMenu.append(document.createElement("hr"));
    contextMenu.append(
      this.createDivColor("orange", date, order, instructor_id, ess),
    );
    // contextMenu.append(this.createDivColor('rouge', date, order, instructor_id));
    contextMenu.append(
      this.createDivColor("violet", date, order, instructor_id, ess),
    );
    contextMenu.append(this.createDivColor("bleu", date, order, instructor_id, ess));
    contextMenu.append(this.createDivColor("vert", date, order, instructor_id, ess));
    contextMenu.append(this.createDivColor("gris", date, order, instructor_id, ess));
    contextMenu.append(
      this.createDivColor("aucune", date, order, instructor_id, ess),
    );
    contextMenu.classList.add("visible");
    contextMenu.classList.add("active");
    // }
  }

  color(event) {
    const color = event.target.getAttribute("data-planning-color-value");
    const date = event.target.getAttribute("data-date");
    const order = event.target.getAttribute("data-order");
    const instructor_id = event.target.getAttribute("data-instructor-id");
    const ess = event.target.getAttribute("data-ess");
    console.log("color", color, date, order, instructor_id, ess);
    this.stimulate("Admin::Event#color", color, date, order, instructor_id,ess);
  }

  beforeAddAbsence(event) {
    console.log("beforeAddCourse");
    this.showPanel();
  }

  beforeAddText(event) {
    console.log("before add texte");
    this.showPanel();
  }

  beforeEditText(event) {
    console.log("before edit texte");
    this.showPanel();
    this.showEventForm();
  }

  afterAddAbsence(event) {
    console.log("afterAddAbsence");
    const contextMenu = document.getElementById("context-menu");
    contextMenu.classList.remove("visible");
    contextMenu.classList.remove("active");
    // Find div #event-courses
    const eventCourses = document.getElementById("event-courses");
    const eventForm = document.getElementById("event-form");
    // Remove class "scroll-area-lg"
    eventCourses.classList.add("invisible");
    eventForm.classList.remove("invisible");
  }

  afterAddText(event) {
    console.log("afterAddAbsence");
    this.afterAddAbsence(event);
  }

  afterCreateText(event) {
    console.log("afterCreateText");
    this.afterCancel(event);
  }

  afterUpdateText(event) {
    console.log("afterUpdateText");
    this.afterCancel(event);
  }

  toggleEstablishment(event) {
    event.preventDefault();
    console.log(this.idValue);
    let establishment = document.getElementById(this.idValue);
    if (establishment.classList.contains("invisible")) {
      establishment.classList.remove("invisible");
      // store the id of the establishment in the local storage
      localStorage.setItem("establishment_id", this.idValue);
    } else {
      establishment.classList.add("invisible");
      // remove the id of the establishment in the local storage
      localStorage.removeItem("establishment_id");
    }
  }

  afterDelete(event) {
    console.log("afterDelete");
    renderEstablishmentVisibility();
  }

  afterCancel() {
    console.log("afterCancel");
    const eventCourses = document.getElementById("event-courses");
    const eventForm = document.getElementById("event-form");
    // Remove class "scroll-area-lg"
    eventCourses.classList.remove("invisible");
    eventForm.classList.add("invisible");
  }

  showEventForm() {
    console.log("showEventForm");
    const eventCourses = document.getElementById("event-courses");
    const eventForm = document.getElementById("event-form");
    eventForm.classList.remove("invisible");
    eventCourses.classList.add("invisible");
  }

  beforeEditAbsence(event) {
    console.log("beforeEditAbsence");
    const eventCourses = document.getElementById("event-courses");
    const eventForm = document.getElementById("event-form");
    eventForm.classList.remove("invisible");
    eventCourses.classList.add("invisible");
  }

  beforeCreateAbsence(event) {
    console.log("beforeCreateAbsence");
    // Disable submit button and cancel button
    const submitButton = document.getElementById("submit-absence");
    const cancelButton = document.getElementById("cancel-absence");
    submitButton.disabled = true;
    cancelButton.disabled = true;

    // add a spinner to the submit button
    submitButton.value = "Enregistrement en cours...";
    console.log(submitButton);
  }

  afterCreateAbsence(event) {
    console.log("afterCreateAbsence");
    this.afterCancel(event);
  }

  afterUpdateAbsence(event) {
    console.log("afterUpdateAbsence");
    this.afterCancel(event);
  }
  showCourse(event) {
    console.log("showCourse", this.idValue);
    this.showPanel();
    if (this.idValue) {
      this.stimulate("Admin::Event#showCourseDetail", this.idValue);
    }
  }

  showAbsence(event) {
    console.log("showAbsence", this.idValue);
    this.showPanel();
    if (this.idValue) {
      this.stimulate("Admin::Event#showAbsence", this.idValue);
    }
  }

  showPanel() {
    const wrapper = document.querySelector(".drawer-content-wrapper");

    const hamburger = document.querySelector(".hamburger-planing");

    if (hamburger) {
      hamburger.style.transform = "translate3d(0, 6px, 0) rotate(135deg)";
    }

    if (wrapper.classList.contains("hide")) {
      wrapper.classList.remove("hide");
      wrapper.parentElement.classList.remove("hide");
    }
  }

  dragstart(event) {
    console.log("dragstart");
    const id = event.target.getAttribute("data-planning-id-value");
    console.log(id);
    // const days = event.target.getAttribute("data-available-days");
    const method = event.target.getAttribute("data-method");
    event.dataTransfer.setData("application/drag-id", id);
    // event.dataTransfer.setData("application/drag-days", days);
    event.dataTransfer.setData("application/drag-method", method);
    // event.dataTransfer.setData("text/plain", event.target.innerText);
    // event.dataTransfer.setData("text/html", event.target.outerHTML);
    event.dataTransfer.dropEffect = "move";
  }

  dragover(event) {
    event.preventDefault();
    // const target = event.target;
    // const day = event.target.getAttribute("data-day");
    // const available_days = eval(
    //   event.dataTransfer.getData("application/drag-days")
    // );
    // if (typeof available_days == "undefined") {
    //   return false;
    // }

    // this.backgroundValue = target.style.backgroundColor;
    // // Add colors to target
    // if (available_days.includes(day)) {
    //   // Green
    //   target.style.backgroundColor = "rgba(255, 249, 222, 0.62)";
    // } else if (available_days.length == 0) {
    //   // Blue
    //   target.style.backgroundColor = "rgba(32, 178, 170, 0.32)";
    // } else {
    //   // Red
    //   target.style.backgroundColor = "rgba(255, 69, 0, 0.32)";
    // }
  }

  dragleave(event) {
    // console.log("dragleave", this.backgroundValue);
    // event.preventDefault();
    // event.target.style.backgroundColor = this.backgroundValue //"rgba(0, 0, 0, 0)";

    return true;
  }

  drop(event) {
    event.preventDefault();

    const target = event.target;
    console.log("drop", event.dataTransfer.getData("application/drag-id"));
    event.dataTransfer.dropEffect = "move";
    const id = event.dataTransfer.getData("application/drag-id");
    const method = event.dataTransfer.getData("application/drag-method");
    const date = target.getAttribute("data-date");
    const order = target.getAttribute("data-order");
    const instructor_id = target.getAttribute("data-instructor-id");
    if (typeof id == "undefined") {
      return false;
    }
    console.log("drop", method);
    console.log("instructor", instructor_id);
    console.log("order", order);
    console.log("date", date);
    if (instructor_id == "" || instructor_id == null) {
      // event.target.style.backgroundColor = "rgba(0, 0, 0, 0)";
      this.stimulate(`Admin::Event#delete`, id);

      return false;
    }

    if (method == "create") {
      document.getElementById(`course-${id}`).style.display = "none";
    }

    this.stimulate(`Admin::Event#${method}`, id, date, instructor_id, order);
    // Remove target color
    // event.target.style.backgroundColor = "rgba(0, 0, 0, 0)";
  }

  closeContextMenu() {
    const contextMenu = document.getElementById("context-menu");
    if (contextMenu != undefined) {
      contextMenu.classList.remove("visible");
      let items = document.querySelectorAll(".item");
      items.forEach((item) => {
        item.remove();
      });
    }
  }

  toTheMoon() {
    // When the user clicks on the button, scroll to the top of the document
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  toggleHeaderNav(event) {
    event.preventDefault();
    console.log("toggleHeaderNav");
    // get by class .planning-drawer-wrapper
    const wrapper = document.querySelector(".drawer-content-wrapper");

    const hamburger = document.querySelector(".hamburger-planing");

    if (wrapper.classList.contains("hide")) {
      wrapper.classList.remove("hide");
      wrapper.parentElement.classList.remove("hide");
      if (hamburger) {
        hamburger.style.transform = "translate3d(0, 6px, 0) rotate(135deg)";
      }
    } else {
      wrapper.classList.add("hide");
      wrapper.parentElement.classList.add("hide");

      if (hamburger) {
        hamburger.style.transform = "translate3d(0, 6px, 0) rotate(90deg)";
      }
    }

    console.log(hamburger.style.transform);
  }

  toggle_periodes_checkbox(event) {
    const target = event.target;
    const checked = target.checked;
    console.log("toggle_periodes_checkbox", checked);
    // toggle all periodes checkbox inside table.periodes
    const periodes = document.querySelectorAll(
      ".periodes input[type=checkbox]",
    );
    periodes.forEach((periode) => {
      if (checked) {
        periode.checked = true;
      } else {
        periode.checked = false;
      }
    });
  }

  // create a button to update plan periode
  input_plan_periode(event) {
    event.preventDefault();
    const target = event.target;

    const id = target.dataset.id;
    // Append button to the target if not exist
    if (document.getElementById(`plan-periode-${id}`) == null) {
      const button = document.createElement("button");
      button.id = `plan-periode-${id}`;
      button.classList.add(
        "btn",
        "btn-sm",
        "btn-primary",
        "fit-content",
        "ml-2",
        "update-plan-periode",
      );
      button.innerText = "OK";
      button.dataset.id = id;
      button.addEventListener("click", (event) => {
        event.preventDefault();
        const value = target.innerText;
        this.update_plan_periode(id, value);
      });
      target.after(button);
    }
  }

  // Create a function that create a dynamic form with a texte area to add free text in the event target
  input_event_text(event) {
    console.log("input_event_text");
    event.preventDefault();
    const target = event.target;
    const id = target.dataset.id;

    // replace innerText by a form
    const form = document.createElement("form");
    form.id = `event-text-${id}`;
    form.classList.add("form-inline");
    form.innerHTML = `
      <textarea class="form-control" id="event-textarea-${id}" rows="3" style="width: 100%;"></textarea>
      <button class="btn btn-primary ml-2" id="event-text-submit-${id}">OK</button>
      `;
    target.replaceWith(form);
  }

  update_plan_periode(id, value) {
    console.log("update_plan_periode", id, value);
    this.stimulate("Admin::Event#update_periode", id, value);
    const button = document.getElementById(`plan-periode-${id}`);
    button.remove();
  }
}
