import ApplicationController from "./application_controller";
import Cleave from "cleave.js";
/* This is the custom StimulusReflex controller for the Etab::Dashboard Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */

export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
   */
  static values = { id: Number, title: String };
  static targets = ["source"];

  connect() {
    super.connect();
    // add your code here, if applicable
  }

  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="etab/dashboard" to your markup alongside
   * data-reflex="Etab::Dashboard#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->Etab::Dashboard#dance" data-controller="etab/dashboard">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Etab::Dashboard#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  beforeNew(element, reflex, noop, reflexId) {
    this.openDrawer();
    this.listenCloseDrawerClick();
  }

  beforeNewTeacher(element, reflex, noop, reflexId) {
    this.openDrawer();
    this.listenCloseDrawerClick();
  }

  beforeEdit(element, reflex, noop, reflexId) {
    this.openDrawer();
    this.listenCloseDrawerClick();
  }

  beforeEditBase(element, reflex, noop, reflexId) {
    this.openDrawer();
    this.listenCloseDrawerClick();
  }

  beforeEditTimetable(element, reflex, noop, reflexId) {
    this.openDrawer();
    this.listenCloseDrawerClick();
  }

  editTimetableSuccess(element, reflex, noop, reflexId) {
    $(".time")
      .toArray()
      .forEach(function (input) {
        new Cleave(input, {
          time: true,
          timePattern: ["h", "m"],
        });
      });
  }

  deleteBuilding(event) {
    event.preventDefault();
    this.delete("Etab::Building#delete", this.idValue, this.titleValue);
  }

  deletePerson(event) {
    event.preventDefault();
    this.delete("Etab::Person#delete", this.idValue, this.titleValue);
  }

  deleteCourse(event) {
    event.preventDefault();
    this.delete("Etab::Course#delete", this.idValue, this.titleValue);
  }

  checkBuildings(event) {
    event.preventDefault();
    console.log("Check", this.idValue);
    this.check(
      "Etab::Establishment#check",
      this.idValue,
      "les bâtiments",
      "buildings",
      "Avez-vous bien vérifié que les adresses et horaires des bâtiments sont correctes ?",
    );
  }

  checkPeople(event) {
    event.preventDefault();
    console.log("Check", this.idValue);
    this.check(
      "Etab::Establishment#check",
      this.idValue,
      "les collaborateurs·rice·s",
      "people",
      "Avez-vous bien vérifié que les coordonnées des collaborateur·rice·s sont correctes ?",
    );
  }

  checkEstablishment(event) {
    event.preventDefault();
    console.log("Check", this.idValue);
    this.check(
      "Etab::Establishment#check",
      this.idValue,
      "l'établissement",
      "establishment",
      "Avez-vous bien vérifié que les coordonnées de l'établissement sont correctes ?",
    );
  }

  checkDocuments(event) {
    event.preventDefault();
    console.log("Check", this.idValue);
    this.check(
      "Etab::Document#check",
      this.idValue,
      "les documents",
      "documents",
      "Merci d'avoir lu les documents.",
    );
  }

  checkPeople(event) {
    event.preventDefault();
    console.log("Check", this.idValue);
    this.check(
      "Etab::Person#check",
      this.idValue,
      "les collaborateur·rice·s",
      "people",
      "Avez-vous bien ajouté tous les collaborateur·rice·s ?",
    );
  }

  checkCourses(event) {
    event.preventDefault();
    console.log("Check", this.idValue);
    this.check(
      "Etab::Course#check",
      this.idValue,
      "les cours",
      "courses",
      "Avez-vous bien ajouté toutes les classes ?",
    );
  }

  foo(event) {
    console.log("bar");
  }
}
