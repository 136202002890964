import ApplicationController from "./application_controller";

/* This is the custom StimulusReflex controller for the Admin::LinkedPrestations Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ["input"];
  connect() {
    super.connect();
    // add your code here, if applicable
  }

  beforeNew(element, reflex, noop, reflexId) {
    this.openDrawer();
    this.listenCloseDrawerClick();
    // $("#app-drawer-overlay").on(
    //   "dragover",
    //   { controller: this },
    //   this.dragover,
    // );
    // $("#app-drawer-overlay").on("drop", { controller: this }, this.drop);
  }

  dragstart(event) {
    const id = event.target.getAttribute("data-id");
    const establishment_id = event.target.getAttribute("data-establishment");
    console.log("Start dragging", id);
    event.dataTransfer.setData("application/drag-id", id);
    event.dataTransfer.setData(
      "application/drag-establishment",
      establishment_id,
    );
    event.dataTransfer.setData("text/plain", event.target.innerText);
    event.dataTransfer.setData("text/html", event.target.outerHTML);
    event.dataTransfer.dropEffect = "move";
  }

  dragover(event) {
    event.preventDefault();
    event.originalEvent.dataTransfer.dropEffect = "move";
    $(".app-drawer-overlay")
      .css("background", "rgba(255,249,222, 0.32)")
      .css("transition", "background 0.5s linear");
    return true;
  }

  dragoverForm(event) {
    event.preventDefault();
    $(".app-drawer-overlay").css("background", "rgba(0,0,0, 0.32)");
    return true;
  }

  drop(event) {
    event.preventDefault();

    $(".app-drawer-overlay").css("background", "rgba(0,0,0, 0.32)");
    event.dataTransfer = event.originalEvent.dataTransfer;
    event.dataTransfer.dropEffect = "move";
    const id = event.dataTransfer.getData("application/drag-id");
    const establishment = event.dataTransfer.getData(
      "application/drag-establishment",
    );

    let date_range = $("#date_range").val();
    let scholar_year = $("#scholar_year").val();
    console.log("Drop", id, establishment, date_range, scholar_year);
    event.data.controller.stimulate(
      "Admin::LinkedPrestation#create",
      id,
      establishment,
      date_range,
      scholar_year,
    );
  }

  dragenter(event) {
    event.preventDefault();
    console.log("drag enter");
  }

  dragend(event) {
    event.preventDefault();
    console.log("drag end");
  }

  dragleave(event) {
    console.log("Drag leave");
  }

  fetchPrestations(query, establishment) {
    console.log("Fetching prestations...", query);
    this.stimulate("Admin::LinkedPrestation#search", query, establishment);
  }

  onInputChange(event) {
    console.log("input");
    this.element.removeAttribute("value");
    if (this.hasHiddenTarget) this.hiddenTarget.value = "";

    const query = this.inputTarget.value.trim();
    const establishment = this.inputTarget.dataset.establishment;
    console.log(establishment);
    if (query && query.length >= 0) {
      this.fetchPrestations(query, establishment);
    } else {
      console.log("Do nothing");
    }
  }

  isSelected(event) {
    let cb = document.getElementsByClassName("prestations-checkbox");
    let btn = document.getElementById("add-prestations");
    if (cb) {
      if ([...cb].some((x) => x.checked)) {
        btn.style.display = "block";
      } else {
        btn.style.display = "none";
      }
    }
  }

  debounce(fn, delay = 300) {
    let timeoutId = null;

    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(fn, delay);
    };
  }
}
