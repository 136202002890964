import ApplicationController from "./application_controller";

/* This is the custom StimulusReflex controller for the Admin Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
   */
  static values = { id: Number, title: String };

  connect() {
    super.connect();
    // add your code here, if applicable
  }



  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="admin" to your markup alongside
   * data-reflex="Admin#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->Admin#dance" data-controller="admin">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Admin#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */
  beforeShow(element, reflex, noop, reflexId) {
    this.openDrawer(element.dataset);
    this.listenCloseDrawerClick();
  }

  beforeNew(element, reflex, noop, reflexId) {
    console.log("Before new");
    this.openDrawer();
    this.listenCloseDrawerClick();
  }

  afterNew(element, reflex, noop, reflexId) {
    this.formatForm();

  }

  afterEdit(element, reflex, noop, reflexId) {
    this.formatForm();
  }
  
  afterUpdate(element, reflex, noop, reflexId) {
    element.reset();
  }

  afterCreate(element, reflex, noop, reflexId) {
    element.reset();
  }

  beforeEdit(element, reflex, noop, reflexId) {
    this.openDrawer(element.dataset);
    this.listenCloseDrawerClick();
  }

  beforeEditDays(element, reflex, noop, reflexId) {
    this.openDrawer(element.dataset);
    this.listenCloseDrawerClick();
  }

  formatForm() {
    console.log("formatForm");
    // const config = {
    //   on: "Oui",
    //   off: "Non",
    // };
    // $("#parent").bootstrapToggle(config);
    // $("#forfait_transport").bootstrapToggle(config);
    // $("#relation_km_temps").bootstrapToggle(config);
    // $("#actif").bootstrapToggle(config);
  }

  deleteExpense(event) {
    event.preventDefault();
    this.delete("Efssr::Expense#delete", this.idValue, this.titleValue);
  }

  showNotifications(event){
    event.preventDefault()
    console.log('showNotifications');
    
  }

  closeDrawer() {

    window.closeDrawer();
  }

}
