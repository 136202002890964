import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import { Modal } from "bootstrap";
/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  // TODO: move this to a global config file for establishment
  emailDomain = "@edu.vs.ch";

  connect() {
    StimulusReflex.register(this);
  }

  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  toggleSidebar(event) {
    console.log("toggle sidebar");

    var containerElement = document.querySelector(".app-container");
    $(containerElement).toggleClass("closed-sidebar");

    // update left pixel of th cells inside tbody
    var table = document.querySelector(".sticky-table");
    if (table) {
      // select th and td elements
      var tableCells = table.querySelectorAll("th");

      if (containerElement.classList.contains("closed-sidebar")) {
        // set the left pixel of the cells
        var left = 80;
        tableCells.forEach(function (cell) {
          cell.style.left = left + "px";
          cell.style.width = "10%";
        });
      } else {
        // set the left pixel of the cells
        var left = 230;
        tableCells.forEach(function (cell) {
          cell.style.left = left + "px";
          cell.style.width = "20%";
        });
      }
    }
  }

  toggleNavbar() {
    console.log("toggle navbar");

    $(".mobile-toggle-nav").toggleClass("is-active");
    $(".app-container").toggleClass("sidebar-mobile-open");
  }

  toggleHeaderNav() {
    console.log("toggle header nav");

    $(".mobile-toggle-header-nav").toggleClass("active");
    $(".app-header__content").toggleClass("header-mobile-open");
  }

  beforeReflex(element, reflex, noop, reflexId) {
    // document.body.classList.add('wait')
  }

  reflexSuccess(element, reflex, noop, reflexId) {
    // show success message
  }

  reflexError(element, reflex, error, reflexId) {
    // show error message
  }

  reflexHalted(element, reflex, error, reflexId) {
    // handle aborted Reflex action
  }

  afterReflex(element, reflex, noop, reflexId) {
    // document.body.classList.remove('wait')
  }

  finalizeReflex(element, reflex, noop, reflexId) {
    // all operations have completed, animation etc is now safe
  }

  openDrawer(dataset = {}) {
    console.log("open drawer in application controller");
    $(".app-drawer-wrapper").addClass("drawer-open");
    $(".app-drawer-overlay").removeClass("d-none");
    if (dataset.drawer == "large") {
      document.getElementById("app-drawer-wrapper").style.width = "600px";
    }
    if (dataset.drawer == "xlarge") {
      document.getElementById("app-drawer-wrapper").style.width = "800px";
    }
  }

  closeDrawer() {
    window.closeDrawer();
  }

  listenCloseDrawerClick() {
    let localCloseDrawer = this.closeDrawer;
    $(".drawer-nav-btn").on("click", function () {
      localCloseDrawer();
    });
  }

  delete(reflex_event, id, title) {
    let modalWrap = document.createElement("div");
    // add class to modalWrap

    modalWrap.innerHTML = `
      <div id="modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true" >
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Êtes-vous bien sûr?</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                      </button>
                  </div>
                  <div class="modal-body">
                      <p class="mb-0">Voulez-vous effacer ${title}?</p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                      <button type="button" class="btn btn-primary modal-success-btn" data-bs-dismiss="modal">Effacer</button>
                  </div>
              </div>
          </div>
      </div>
      `;
    const dashboard = this;
    let callback = function () {
      dashboard.stimulate(reflex_event, id);
    };
    modalWrap.querySelector(".modal-success-btn").onclick = callback;
    document.body.append(modalWrap);

    let modal = new Modal(modalWrap.querySelector("#modal"));
    modal.show();
  }

  check(reflex_event, id, title, checklist, msg) {
    console.log("check");
    let modalWrap = document.createElement("div");
    modalWrap.classList.add("modal-wrap");
    modalWrap.innerHTML = `
      <div id="modal" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true" style="display: block;" >
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Voulez-vous valider ${title}?</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                      </button>
                  </div>
                  <div class="modal-body">
                      <p class="mb-0">${msg}</p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                      <button type="button" class="btn btn-primary modal-success-btn" data-bs-dismiss="modal">Valider</button>
                  </div>
              </div>
          </div>
      </div>
      `;
    const dashboard = this;
    let callback = function () {
      console.log("stimulate check", id, checklist);
      dashboard.stimulate(reflex_event, id, checklist);
    };
    modalWrap.querySelector(".modal-success-btn").onclick = callback;
    document.body.append(modalWrap);
    console.log(document.body.childNodes);
    let modal = new Modal(modalWrap.querySelector("#modal"));

    modal.show();
    return false;
  }

  fill_email(event) {
    console.log("fill_email admin");
    event.preventDefault();
    // find the email input from event target
    const email = event.target
      .closest("form")
      .querySelector("input[name='person[email]']");
    // Find input with name="person[firstname]"
    const firstname =
      event.target
        .closest("form")
        .querySelector("input[name='person[firstname]']") || "";
    const lastname =
      event.target
        .closest("form")
        .querySelector("input[name='person[lastname]']") || "";
    // convert firstname and lastname for email without accents and ï and ë and ...
    const emailValue = `${firstname.value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")}.${lastname.value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")}${this.emailDomain}`;
    // fill the email input with the email value
    email.value = emailValue;
  }
}
