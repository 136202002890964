// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// import('styles/architect.css')
// import("styles/admin/base.scss");
// import("styles/application.scss");
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import "channels";
import "bootstrap";
import PerfectScrollbar from "perfect-scrollbar";
import SlimSelect from 'slim-select'
import "trix"
import "@rails/actiontext"
window.SlimSelect = SlimSelect

// import introJs from "intro.js";
import "controllers";



require("jquery");
Rails.start();
Turbolinks.start();

function dragover_handler(ev) {
  ev.preventDefault();
  ev.dataTransfer.dropEffect = "move";
}

function closeDrawer() {
  console.log('close drawer in application.js');
  document.getElementById("app-drawer-wrapper").style.width = "450px";
  $(".app-drawer-wrapper").removeClass("drawer-open");
  $(".app-drawer-overlay").addClass("d-none");
  $(".open-right-drawer").removeClass("is-active");

  // empty #drawer-container content if exists
  if (document.getElementById("drawer-container")) {
    document.getElementById("drawer-container").innerHTML = "<h3 class='drawer-heading' id='drawer-heading'></h3><div class='drawer-section' id='drawer-section'></div>";
  }

}
window.closeDrawer = closeDrawer;


function renderEstablishmentVisibility() {
  // get the id of the establishment in the local storage
  const establishment_id = localStorage.getItem("establishment_id");
  if (establishment_id) {
    let establishment = document.getElementById(establishment_id);
    if (establishment) {
      establishment.classList.remove("invisible");
    }
  }
}
window.renderEstablishmentVisibility = renderEstablishmentVisibility;

// 
function buildMemberGroup() {
  console.log('buildMemberGroup')
  $("[data-form-prepend]").click(function(e) {
      e.preventDefault();
      var obj = $($(this).attr("data-form-prepend"));
      obj.find("input, select, textarea").each(function() {
          $(this).attr("name", function() {
              return $(this)
                  .attr("name")
                  .replace("new_record", new Date().getTime());
          });
      });
      obj.insertBefore(this.parentElement.parentElement);
      return false;
  });
} 

window.buildMemberGroup = buildMemberGroup;


jQuery(() =>  {
  console.log("application.js");
  

  renderEstablishmentVisibility();

  document.addEventListener("renderEstablishmentVisibility", function () {
    renderEstablishmentVisibility();
  });

  document.addEventListener("buildMemberGroup", function () {
    buildMemberGroup();
  });


  document.addEventListener("addScrollbar", function () {
    console.log("listen to scrollbar");
    if ($(".scrollbar-container")[0]) {
      console.log("scrollbar container added");
      $(".scrollbar-container").each(function () {
        const ps = new PerfectScrollbar($(this)[0], {
          wheelSpeed: 2,
          wheelPropagation: false,
          minScrollbarLength: 20,
        });
      });
    }

    if ($(".scrollbar-sidebar")[0]) {
      console.log("scrollbar sidebar added");
      const ps = new PerfectScrollbar(".scrollbar-sidebar", {
        wheelSpeed: 2,
        wheelPropagation: true,
        minScrollbarLength: 20,
      });
    }
  });

  $(".drawer-nav-btn").on("click", function () {
    closeDrawer();
  });

  document.addEventListener("keydown", function (e) {
    if (e.keyCode == 27) {
      console.log("esc pressed")
      //Close drawer key 'esc' key is pressed
      closeDrawer();
      // remote button with class 'update-plan-periode' if exists
      if (document.querySelector(".update-plan-periode")) {
        document.querySelector(".update-plan-periode").remove();
      }
    }
  });

  document.addEventListener("closeDrawer", function (e) {
    closeDrawer();
  });

  document.addEventListener("timeIsTime", function (e) {
    // Cleave.js
    $(".time")
      .toArray()
      .forEach(function (input) {
        new Cleave(input, {
          time: true,
          timePattern: ["h", "m"],
        });
      });
  });

  // Listen to scheduler checkbox and enable/disable checkboxes on same table row
  document.addEventListener("listenCheckboxScheduler", function (e) {
    const schedulers = document.querySelectorAll(
      'input[type="checkbox"].scheduler'
    );
    console.log(schedulers);
    schedulers.forEach((cb) => {
      cb.addEventListener("change", (e) => {
        let tr = e.currentTarget.parentNode.parentNode;
        console.log(tr);
        sub;
        let checkboxes = tr.querySelectorAll('input[type="checkbox"]');
        console.log(checkboxes);
        if (e.target.checked) {
          console.log("Checked");
          checkboxes.forEach((cb) => {
            cb.checked = true;
          });
        } else {
          console.log("unchecked");
          checkboxes.forEach((cb) => {
            cb.checked = false;
          });
        }
      });
    });
  });

  // document.addEventListener("addSlimSelect", function (e) {
  //   new SlimSelect({
  //     select: '#multiselect',
  //     settings: {
  //       placeholderText: 'Une ou plusieurs catégories',
  //       showSearch: false,
  //     }
  //   })
  // });

  
});



document.addEventListener("clear-forms", function (event) { 
  // Find all forms and reset them
  console.log('reset forms');
  const forms = document.querySelectorAll("form");
  forms.forEach((form) => {
    form.reset();
  });
  // Find all inputs except submit  and clear them
  const inputs = document.querySelectorAll("input:not([type='submit'])");
  inputs.forEach((input) => {
    input.value = "";
  });

  // Find all selects and clear them
  const selects = document.querySelectorAll("select");
  selects.forEach((input) => {
    input.value = "";
  });

  buildMemberGroup();
 
}) ;


require("trix")
require("@rails/actiontext")